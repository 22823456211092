/*  __ __ ___    ____  __  ___   _____ 
   / //_//   |  / __ \/ / / / | / /   |
  / ,<  / /| | / /_/ / / / /  |/ / /| |
 / /| |/ ___ |/ _, _/ /_/ / /|  / ___ |
/_/ |_/_/  |_/_/ |_|\____/_/ |_/_/  |_|

*/

/*:::::: INCLUDES ::::::*/
	/* http://meyerweb.com/eric/tools/css/reset/ 
	   v2.0 | 20110126
	   License: none (public domain)
	*/

	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed, 
	figure, figcaption, footer, header, hgroup, 
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}
	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure, 
	footer, header, hgroup, menu, nav, section {
		display: block;
	}
	body {
		line-height: 1;
	}
	ol, ul {
		list-style: none;
	}
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	@import "perfect-scrollbar";

/*:::::: TYPOGRAPHY ::::::*/
	@import "fonts";
	@import "fa/font-awesome";

	@mixin Aleo ($size, $style:"", $weight:"", $line-height:""){
		font-family: 'Aleo', Cambria, Georgia, serif;
		font-size: $size;
		line-height: #{$line-height};
		font-weight: #{$weight};
		font-style: #{$style};
	}

	@mixin Tablet ($size, $style:"", $weight:"", $line-height:""){
		font-family: 'tablet-gothic-compressed', 'Arial Narrow', 'Trebuchet MS', Arial, sans-serif;
		font-size: $size;
		line-height: #{$line-height};
		font-weight: #{$weight};
		font-style: #{$style};
	}

	h1, h2, h3, h4, h5, h6 {
		font-family: 'tablet-gothic-compressed', 'Arial Narrow', 'Trebuchet MS', Arial, sans-serif;
	}

/*:::::: HIGH LEVEL ELTS ::::::*/
	*, *:before, *:after {
		-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
	}

	html, body {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.clearfix:before,
	.clearfix:after {
	  content: "";
	  display: table;
	}
	 
	.clearfix:after {
	  clear: both;
	}
	 
	.clearfix {
	  zoom: 1; /* ie 6/7 */
	}

	.scrollable {
	    -webkit-overflow-scrolling: touch;
	}

/*:::::: VARIABLES ::::::*/
	$brown: #5c4925;
	$green: #94d500;
	$warm-gray: #ccc6ba;
	$med-warm-gray: #e8e6e3;
	$light-warm-gray: #f4f2f0;
	$orange: #ff8300;
	$yellow: #FFD755;
	$blue: #1398E2;
	$red: rgb(181, 18, 72);
	$violet: #FF1DEA;
	$burnt-orange: #DD4706;
	$teal: #00CFA7;

/*:::::: MAIN ::::::*/
	//Hamburger Icon
		$button-size: 30px;
		$transition: 200ms;
		@mixin line($width: $button-size) {
			display: block;
			width: $width;
			height: 4px;
			background: #ffffff;
			-webkit-transition: $transition;
			-moz-transition: $transition;
			-o-transition: $transition;
			transition: $transition;
		}

		.hamburger {
			position: fixed;
			top: 15px;
			left: 10px;
			display: none;
			padding: 25px 14px 25px 12px;
			cursor: pointer;
			user-select: none;
			background-color: $green;
			-webkit-tap-highlight-color: rgba(0,0,0,0);
			z-index: 1000;
			-webkit-border-radius: 28px;
			-moz-border-radius: 28px;
			border-radius: 28px;
			-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.7);
			-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.7);
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.7);
			-webkit-transition: all 0.5s;
			-moz-transition: all 0.5s;
			transition: all 0.5s;

			&:hover {
				background-color: darken($green, 5%);
			}
		}

		.lines {
			position: relative; 
			@include line($width: $button-size);

			&:before, &:after {
				content: '';
				position: absolute;
				right: 0;
				@include line($width: $button-size - 4px);
				-webkit-transform-origin: 0 center;
				-moz-transform-origin: 0 center;
				-ms-transform-origin: 0 center;
				-o-transform-origin: 0 center;
				transform-origin: 0 center;
		  	}
			&:before { top: 8px; }
			&:after { top: -8px; }
		}

		.hamburger.close {
			-webkit-transform: translate3d(300px, 0px, 0px);
			transform: translate3d(300px, 0px, 0px);
		}

		.hamburger.close .lines{
			background: transparent;

			&:before, &:after{
				-webkit-transform-origin: 50% 50%;
				-moz-transform-origin: 50% 50%;
				-ms-transform-origin: 50% 50%;
				-o-transform-origin: 50% 50%;
				transform-origin: 50% 50%;
				top: 0;
				right: -1px;
				width: $button-size;
			}
			&:before{
				-webkit-transform: rotate3d(0,0,1,45deg);
				-moz-transform: rotate3d(0,0,1,45deg);
				-ms-transform: rotate3d(0,0,1,45deg);
				-o-transform: rotate3d(0,0,1,45deg);
				transform: rotate3d(0,0,1,45deg); 
			}
		    &:after{
		    	-webkit-transform: rotate3d(0,0,1,-45deg);
		    	-moz-transform: rotate3d(0,0,1,-45deg);
		    	-ms-transform: rotate3d(0,0,1,-45deg);
		    	-o-transform: rotate3d(0,0,1,-45deg);
				transform: rotate3d(0,0,1,-45deg); 
		    }
		}


	//General
		#menu_wrapper {
			display: block;
			width: 100%;
			height: 100%;
			position: relative;
			overflow: hidden;
		}
		.site_wrapper {
			background-image: url(../images/tex/wornwood.jpg);
			background-repeat: repeat;
			position: relative;
			overflow-y: scroll;
			width: 100%;
			height: 100%;
		}
		input[type=text],
		input[type=email],
		input[type=tel], 
		textarea {
			-webkit-transition: border 0.30s ease-in-out, box-shadow 0.30s ease-in-out;
			-moz-transition: border 0.30s ease-in-out, box-shadow 0.30s ease-in-out;
			-ms-transition: border 0.30s ease-in-out, box-shadow 0.30s ease-in-out;
			-o-transition: border 0.30s ease-in-out, box-shadow 0.30s ease-in-out;
			outline: none;
			padding: 3px 0px 3px 3px;
			margin: 5px 1px 3px 0px;
			border: 1px solid #DDDDDD;
		}
		 
		input[type=text]:focus,
		input[type=email]:focus,
		input[type=tel]:focus, 
		textarea:focus {
			box-shadow: 0 0 5px $blue;
			padding: 3px 0px 3px 3px;
			margin: 5px 1px 3px 0px;
			border: 1px solid $blue;
		}
	
	//Header
		.site_wrapper > header {
			-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
			-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
		}
		.image_masthead {
			display: block;
			width: 100%;
			height: 218px;
			overflow: hidden;
			border-bottom: 1px solid rgba(0,0,0,0.75);

			.masthead_blurred {
				display: block;
				width: 100%;
				height: auto;
				-webkit-filter: blur(3px);
				-moz-filter: blur(3px);
				-o-filter: blur(3px);
				-ms-filter: blur(3px);
				filter: blur(3px);
				position: relative;
				top: -200px;
			}
		}
		//Slider
		.tparrows,
		.tparrows.hidearrows {
			-webkit-transition: opacity 0.5s ease;
			-moz-transition: 	opacity 0.5s ease;
			-o-transition: 		opacity 0.5s ease;
			transition: 		opacity 0.5s ease;
		}
		.tparrows.default.custom {
			background-color: rgba(148, 213, 0, 0.5);
			background-image: url(../images/icons/arrows.png);
			background-repeat: no-repeat;
			background-position: 4px 0px;
			width: 45px !important;
			min-width: 45px !important;
			height: 60px !important;
			-webkit-border-radius: 0px;
			-moz-border-radius: 0px;
			border-radius: 0px;
			-webkit-transition: background-color 0.5s ease;
			-moz-transition: 	background-color 0.5s ease;
			-o-transition: 		background-color 0.5s ease;
			transition: 		background-color 0.5s ease;
			-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
			-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
		}
		.tparrows.default.custom:hover {
			background-color: rgba(148, 213, 0, 0.75) !important;
		}
		.tp-leftarrow.default.custom {
			-webkit-border-top-right-radius: 5px;
			-webkit-border-bottom-right-radius: 5px;
			-moz-border-radius-topright: 5px;
			-moz-border-radius-bottomright: 5px;
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}
		.tp-rightarrow.default.custom {
			background-position: -39px 0px !important;
			-webkit-border-top-left-radius: 5px;
			-webkit-border-bottom-left-radius: 5px;
			-moz-border-radius-topleft: 5px;
			-moz-border-radius-bottomleft: 5px;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}
		.header_tab {
			position: absolute;
			display: block;
			top: 0;right: 2.34375%;;
			z-index: 100;

			& > a {
				display: inline-block;
				vertical-align: top;
			}

			a#h_p_tab {
				background-color: $orange;
				background-image: url(../images/logos/HPTab.svg);
				background-repeat: no-repeat;
				background-position: center;
				text-indent: -9999px;
				width: 160px;
				height: 44px;
				-webkit-border-bottom-right-radius: 5px;
				-webkit-border-bottom-left-radius: 5px;
				-moz-border-radius-bottomright: 5px;
				-moz-border-radius-bottomleft: 5px;
				border-bottom-right-radius: 5px;
				border-bottom-left-radius: 5px;
				-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
				-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
				-webkit-transition: background-color 0.5s ease;
				-moz-transition: 	background-color 0.5s ease;
				-o-transition: 		background-color 0.5s ease;
				transition: 		background-color 0.5s ease;
			}
			a#h_p_tab:hover {
				background-color: darken($orange, 5%);
			}
		}
		.sunrise_search {
			display: inline-block;
			vertical-align: top;
			top: 0;
			margin-left: 20px;
			background-image: url(../images/tex/burlap.jpg);
			padding: 3px 10px;
			-webkit-border-bottom-right-radius: 5px;
			-webkit-border-bottom-left-radius: 5px;
			-moz-border-radius-bottomright: 5px;
			-moz-border-radius-bottomleft: 5px;
			border-bottom-right-radius: 5px;
			border-bottom-left-radius: 5px;
			-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
			-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);

			fieldset {
				padding: 5px 0;
			}

			div {
				display: none;
			}

			label {
				display: inline-block;
				vertical-align: top;
				width: 15px;
				height: 15px;
				background-image: url(../images/icons/mag_glass.svg);
				background-repeat: no-repeat;
				margin-right: 6px;
				margin-left: 2px;
				position: relative;
				top: 5px;
				cursor: pointer;
			}
			input {
				display: inline-block;
				vertical-align: top;
				background-color: rgba(0,0,0,0.2);
				border: none;
				@include Aleo(13px,normal,400,20px);
				padding: 4px 8px 3px;
				color: #ffffff;
				min-width: 175px;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;
				border-bottom: 1px solid rgba(255,255,255,0.2);
				margin: 0;

				&::-webkit-input-placeholder {
				   @include Aleo(13px,italic,200,20px);
				   color: rgba(255,255,255,0.75);
				}

				&:-moz-placeholder { /* Firefox 18- */
				   @include Aleo(13px,italic,200,20px);
				   color: rgba(255,255,255,0.75);  
				}

				&::-moz-placeholder {  /* Firefox 19+ */
				   @include Aleo(13px,italic,200,20px);
				   color: rgba(255,255,255,0.75);  
				}

				&:-ms-input-placeholder {  
				   @include Aleo(13px,italic,200,20px);
				   color: rgba(255,255,255,0.75);  
				}

				&:focus {
					margin: 0 !important;
					padding: 4px 8px 3px !important;
					border: none !important;
					border-bottom: 1px solid rgba(255,255,255,0.2) !important;
					box-shadow: none !important;
				}
			}
		}

	// Left Column
		.left_column {
			display: inline-block;
			vertical-align: top;
			width: 20%;
			margin-right: 2.34375%;
			position: absolute;
			top: 88px;
			left: 0;
			z-index: 200;
			-webkit-transition: top 0.5s ease-in-out;
			-moz-transition: 	top 0.5s ease-in-out;
			-o-transition: 		top 0.5s ease-in-out;
			transition: 		top 0.5s ease-in-out;
		}

		#main_nav {
			display: block;
			width: 100%;

			.mp-back {
				display: none;
			}
		}
		#sunrise_main_logo {
			display: block;
		}
		#sunrise_main_logo svg {
			position: absolute;
			display: none;
		}
		#sunrise_main_logo_responsive {
			display: none;
		}
		@-moz-document url-prefix() { 
			#sunrise_main_logo svg {
				display: block;
			}
		}

		#main_nav ul {
			background-image: url(../images/tex/burlap.jpg);
			background-repeat: repeat;
			-webkit-border-bottom-right-radius: 5px;
			-moz-border-radius-bottomright: 5px;
			border-bottom-right-radius: 5px;
			-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
			-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);

			li a {
				display: block;
				text-align: center;
				border-top: 2px solid rgba(0,0,0,0.5);
				color: rgba(255,255,255,0.7);
				@include Tablet(26px,normal,600,50px);
				letter-spacing: 0.01em;
				text-decoration: none;
				text-transform: uppercase;
				padding-bottom: 10px;
				-webkit-transition: color 0.5s ease;
				-moz-transition: 	color 0.5s ease;
				-o-transition: 		color 0.5s ease;
				transition: 		color 0.5s ease;
			}

			& > li {
				position: relative;
			}

			li ul.sub-menu {
				display: none;
				position: absolute;
				top: 0;
				left: 100%;
				z-index: 9999;
				width: 100%;
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				border-radius: 0;
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;

				background-color: rgba(0,0,0,0.5);
				background-image: none;
				padding: 8% 10%;

				li {
					background-color: #ffffff;

					a {
						display: block;
						text-align: left;
						border-top: 1px solid $warm-gray;
						color: $green;
						@include Aleo(15px,normal,600,20px);
						letter-spacing: 0;
						text-decoration: none;
						text-transform: none;
						padding: 15px 20px 14px;
						-webkit-transition: color 0.5s ease,background-color 0.5s ease;
						-moz-transition: 	color 0.5s ease,background-color 0.5s ease;
						-o-transition: 		color 0.5s ease,background-color 0.5s ease;
						transition: 		color 0.5s ease,background-color 0.5s ease;
					}

					a:hover {
						color: #ffffff;
						background-color: $warm-gray;
					}

					&:first-of-type a {
						border-top: none;
					}
				}
			}

			li a:hover {
				color: rgba(255,255,255,0.95);
			}

			li.parent-link {
				display: none;
			}
		}

	// Main Column
		.site_wrapper > .main_column {
			min-height: 1390px;
		}
		body.compose-mode .main_column article {
			padding-top: 45px;
		}
		.site_wrapper .archive_header {
			display: block;
			width: 75.3125%;
			margin-left: 22.34375%;
			position: absolute;
			top: 50px;

			h1 {
				display: block;
				width: 40%;
				margin: 0 auto;
				text-align: center;
				font-size: 40px;
				text-transform: uppercase;
				color: #ffffff;
				background-color: rgba(0,0,0,0.6);
				padding: 10px 0 13px;
				-webkit-border-radius: 40px;
				-moz-border-radius: 40px;
				border-radius: 40px;
			}
		}
		.main_column {
			display: inline-block;
			vertical-align: top;
			width: 49.75%;
			margin-right: 1.5625%;
			margin-left: 22.34375%;
			position: relative;
			top: -54px;

			&.full_width {
				width: 75.3125%;
			}

			main[data-columns]::before {
				content: '3 .column.size-1of3';
			}

			.column {
				display: inline-block;
				vertical-align: top;
			}
			.size-1of3 {
				width: 32%;
				margin-right: 2%;

				&:nth-child(3) {
					margin-right: 0;
				}
			}

			&.article_roll,
			&.search_roll {
				article {
					.article_title {
						@include Tablet(28px,normal,700,36px);
						color: $brown;
						z-index: 100;
					}

					a.featured_image {
						position: relative;
						display: block;
					}

					.icon {
						position: absolute;
						top: 2%;
						left: 4%;
						font-size: 50px;
						color: #ffffff;
						text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
					}

					blockquote {
						display: block;
						margin: 10px 0;
						padding: 5px 8px;
						background-color: $light-warm-gray;
						border-left: 6px solid $med-warm-gray;
						@include Aleo(15px,italic,200,22px);
					}

					a.see_more {
						display: inline-block;
						@include Tablet(15px,normal,600,21px);
						letter-spacing: 0.05em;
						text-transform: uppercase;
						background-color: $orange;
						padding: 2px 9px 4px;
						text-decoration: none;
						color: #ffffff;
						-webkit-border-radius: 3px;
						-moz-border-radius: 3px;
						border-radius: 3px;
						-webkit-transition: background-color 0.5s ease;
						-moz-transition: 	background-color 0.5s ease;
						-o-transition: 		background-color 0.5s ease;
						transition: 		background-color 0.5s ease;
						margin-bottom: 10px;

						.arrow {
							position: relative;
							top: -1px;
						}
					}

					a.see_more:hover {
						background-color: darken( $orange, 5% ) 
					}

					.article_meta .article_categories {
						display: block;
						margin-right: 0;
						max-width: 250px;
					}
				}
			}

			.load_more {
				display: block;
				width: 65px;
				padding: 22px 0 24px;
				background-color: $orange;
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				border-radius: 5px;
				margin: 0 auto;

				#spinner {
					position: relative;
					display: block;
					width: 100%;
					height: 100%;
				}
			}
			#nomore {
				display: block;
				@include Aleo(14px,normal,700,16px);
				color: #ffffff;
				text-align: center;
				display: none;
				height: 46px;
				text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);
			}

			#featured_article {
				a.image {
					display: block;
					max-width: 40%;
					float: left;
					margin-right: 20px;
					margin-bottom: 20px;
				}
			}
			article {
				background-color: #ffffff;
				padding: 1% 3.5% 2%;
				@include Aleo(15px,normal,200,22px);
				color: #333333;
				-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
				-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
				box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				border-radius: 5px;
				margin-bottom: 30px;
				position: relative;
				overflow: hidden;
				z-index: 100;

				.color_bar {
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					width: 0; 
					height: 0; 
					border-top: 0px solid transparent;
					border-bottom: 12px solid transparent;
					border-right-style: solid;
					border-right-color: $orange;
					border-right-width: 0;
				}

				img {
					max-width: 100%;
					height: auto;
					display: block;
				}
				.post-content.body-image-has-border {
					img {
						border: 1px solid $brown;
					}
				}

				.alignleft {
					float: left;
					margin-right: 2%;
					margin-bottom: 5px;
				}
				.alignright {
					float: right;
					margin-left: 2%;
					margin-bottom: 5px;
				}

				img.featured_image {
					margin-bottom: 15px;
				}

				.article_title,
				.event_title {
					@include Tablet(36px,normal,700,44px);
					color: $brown;
				}
				.article_about_meta,
				.event_posting_meta {
					margin-bottom: 10px;
					display: block;
				}
				h1, h2, h3, h4, h5, h6 {
					color: $brown;
					display: block;
				}
				h1 {
					@include Tablet(36px,normal,700,44px);
				}
				h2 {
					@include Tablet(28px,normal,700,34px);
					border-bottom: 1px solid $warm-gray;
					padding-bottom: 10px;
					margin-bottom: 10px;
				}
				h3 {
					@include Tablet(24px,normal,700,28px);
				}
				h4 {
					@include Tablet(20px,normal,700,26px);
				}
				h5 {
					@include Tablet(18px,normal,700,24px);
				}
				h6 {
					@include Tablet(16px,normal,700,20px);
				}
				h2.location_heading {
					@include Aleo(17px,normal,700,22px);
					border: none;
					padding: 0;
					margin-bottom: 10px;
					color: #333333;
				}
				blockquote {
					display: block;
					@include Aleo(17px,italic,400,22px);
					padding: 10px 12px;
					background-color: $light-warm-gray;
					border-left: 6px solid $med-warm-gray;
				}
				address {
					@include Aleo(15px,normal,400,22px);
					margin-bottom: 10px;
				}
				p {
					display: block;
					margin-bottom: 10px;

					&.text_404 {
						margin-top: 10px;
					}
					strong {
						font-weight: 700;
					}
					em {
						font-style: italic;
					}

					a {
						font-weight: 600;
						color: $green;
						text-decoration: none;
						position: relative;
					}

					a:before {
					  content: "";
					  position: absolute;
					  width: 100%;
					  height: 1px;
					  bottom: -1px;
					  left: 0;
					  background-color: $green;
					  visibility: hidden;
					  -ms-transform: scaleX(0);
					  -webkit-transform: scaleX(0);
					  transform: scaleX(0);
					  -ms-transition: all 0.3s ease-in-out 0s;
					  -webkit-transition: all 0.3s ease-in-out 0s;
					  transition: all 0.3s ease-in-out 0s;
					}

					a:hover:before {
					  visibility: visible;
					  -ms-transform: scaleX(1);
					  -webkit-transform: scaleX(1);
					  transform: scaleX(1);
					}

					small {
						font-size: 12px;
						line-height: 14px;
						font-style: italic;
					}

					&.wp-caption-text {
						display: block;
						text-align: center;
						font-style: italic;
						font-weight: 600;
						padding: 8px 0;
						background-color: $light-warm-gray;
						border-left: 1px solid $warm-gray;
						border-right: 1px solid $warm-gray;
						border-bottom: 1px solid $warm-gray;
					}
				}
				ul {
					display: block;
					list-style: disc;
					margin-left: 15px;
					list-style-position:inside;

					li {
						margin-bottom: 5px;

						ul {
							list-style-position:inside;
							margin-left: 30px;
							list-style: circle;
							margin-top: 5px;
						}
					}

					strong {
						font-weight: 700;
					}

					a {
						font-weight: 600;
						color: $green;
						text-decoration: none;
						position: relative;
					}

					a:before {
					  content: "";
					  position: absolute;
					  width: 100%;
					  height: 1px;
					  bottom: -1px;
					  left: 0;
					  background-color: $green;
					  visibility: hidden;
					  -ms-transform: scaleX(0);
					  -webkit-transform: scaleX(0);
					  transform: scaleX(0);
					  -ms-transition: all 0.3s ease-in-out 0s;
					  -webkit-transition: all 0.3s ease-in-out 0s;
					  transition: all 0.3s ease-in-out 0s;
					}

					a:hover:before {
					  visibility: visible;
					  -ms-transform: scaleX(1);
					  -webkit-transform: scaleX(1);
					  transform: scaleX(1);
					}
				}
				ol {
					display: block;
					list-style: decimal;
					margin-left: 18px;
					list-style-position:inside;

					li {
						margin-bottom: 5px;

						ol {
							list-style-position:inside;
							margin-left: 30px;
							list-style: circle;
						}
					}

					strong {
						font-weight: 700;
					}

					a {
						font-weight: 600;
						color: $green;
						text-decoration: none;
						position: relative;
					}

					a:before {
					  content: "";
					  position: absolute;
					  width: 100%;
					  height: 1px;
					  bottom: -1px;
					  left: 0;
					  background-color: $green;
					  visibility: hidden;
					  -ms-transform: scaleX(0);
					  -webkit-transform: scaleX(0);
					  transform: scaleX(0);
					  -ms-transition: all 0.3s ease-in-out 0s;
					  -webkit-transition: all 0.3s ease-in-out 0s;
					  transition: all 0.3s ease-in-out 0s;
					}

					a:hover:before {
					  visibility: visible;
					  -ms-transform: scaleX(1);
					  -webkit-transform: scaleX(1);
					  transform: scaleX(1);
					}
				}
				table {
					a {
						color: $green;
					}
					th {
						background-color: $med-warm-gray;
						border: 1px solid $warm-gray;
						font-weight: 600;
					}
					tr.odd {
						background-color: $light-warm-gray
					}
					td {
						border: 1px solid $warm-gray;
					}
					th, td {
						padding: 5px 8px;
					}
				}

				abbr, acronym {
					font-weight: 600;
				}
				cite, q {
					font-style: italic;
				}
				sub {
					font-size: 0.5em;
					margin: 0 0.1em;
				}
				sup {
					font-size: 0.5em;
					margin: 0 0.1em;
					position: relative;
					top: -8px;
				}
				ul.section_menu {
					list-style: none;
					margin-left: 0;

					a.featured_image {
						display: inline-block;
						vertical-align: top;
						max-width: 40%;
						margin-right: 2%;
						img {margin-bottom: 0;}
					}

					a:hover:before {
					  visibility: hidden;
					  -ms-transform: scaleX(1);
					  -webkit-transform: scaleX(1);
					  transform: scaleX(1);
					}

					div {
						display: inline-block;
						vertical-align: top;
						width: 58%;
					}

					li {
						margin-bottom: 30px;
					}
				}
				.sunrise_callout {
					display: block;
					margin: 10px 0 !important;
					padding: 15px 20px;
					@include Aleo(20px,italic,200,30px);
					border-width: 8px;
					color: #ffffff;

					&.border-top {
						border-top-style: solid;
					}
					&.border-right {
						border-right-style: solid;
					}
					&.border-bottom {
						border-bottom-style: solid;
					}
					&.border-left {
						border-left-style: solid;
					}

					&.orange {
						border-color: darken($orange, 10%);
						background-color: darken($orange, 5%);
					}
					&.green {
						border-color: darken($green, 15%);
						background-color: darken($green, 10%);
					}
					&.blue {
						border-color: darken($blue, 10%);
						background-color: darken($blue, 5%);
					}
					&.red {
						border-color: darken($red, 10%);
						background-color: $red;
					}
					&.violet {
						border-color: darken($violet, 30%);
						background-color: darken($violet, 25%);
					}
				}
				a.see_more {
					display: inline-block;
					@include Tablet(15px,normal,600,21px);
					letter-spacing: 0.05em;
					text-transform: uppercase;
					background-color: $orange;
					padding: 2px 9px 4px;
					text-decoration: none;
					color: #ffffff;
					-webkit-border-radius: 3px;
					-moz-border-radius: 3px;
					border-radius: 3px;
					-webkit-transition: background-color 0.5s ease;
					-moz-transition: 	background-color 0.5s ease;
					-o-transition: 		background-color 0.5s ease;
					transition: 		background-color 0.5s ease;
					margin-bottom: 10px;

					.arrow {
						position: relative;
						top: -1px;
					}
				}

				a.see_more:hover {
					background-color: darken( $orange, 5% );
				}
				a.sunrise_button {
					display: block;
					width: 100%;
					color:#ffffff;
					font-weight: 700;
					text-align: center;
					text-decoration: none;
					padding: 5px 0;
					margin-bottom: 10px !important;
					-webkit-border-radius: 5px;
					-moz-border-radius: 5px;
					border-radius: 5px;
					-webkit-transition: background-color 0.5s ease;
					-moz-transition: background-color 0.5s ease;
					-o-transition: background-color 0.5s ease;
					transition: background-color 0.5s ease;
				}
				a.sunrise_button.button_color_green {
					background-color: $green;
				}
				a.sunrise_button.button_color_green:hover {
					background-color: darken( $green, 5% );
				}
				a.sunrise_button.button_color_orange {
					background-color: $orange;
				}
				a.sunrise_button.button_color_orange:hover {
					background-color: darken( $orange, 5% );
				}

				// Visual Composer Tabs
				.wpb_content_element .wpb_tabs_nav {
					border-bottom: 1px solid $warm-gray;
					position: relative;
				}
				li.ui-state-default.ui-corner-top {
					background-color: #F9F5ED;
					border: 1px solid $warm-gray;
					border-left: none;
					margin: 0;
					position: relative;
					top: 1px;
					-webkit-transition: background-color 0.5s ease;
					-moz-transition: 	background-color 0.5s ease;
					-o-transition: 		background-color 0.5s ease;
					transition: 		background-color 0.5s ease;

					a {
						@include Tablet(16px,normal,600,24px);
						color: #333333;
						text-transform: uppercase;
					}
					a:hover:before {
					  visibility: hidden;
					  -ms-transform: scaleX(1);
					  -webkit-transform: scaleX(1);
					  transform: scaleX(1);
					}

					&:first-of-type {
						border-left: 1px solid $warm-gray;
					}
				}
				.wpb_content_element .wpb_tabs_nav li.ui-tabs-active,
				.wpb_content_element .wpb_tabs_nav li.ui-tabs-active:hover {
					background-color: #ffffff;
					border-bottom: 1px solid #ffffff;
				}
				.wpb_content_element .wpb_tabs_nav li:hover {
					background-color: #FFFEFC;
				}

				.wpb_content_element.wpb_tabs .wpb_tour_tabs_wrapper .wpb_tab {
					background-color: #ffffff;
					padding: 0;
					margin-top: 20px;
				}
				.wpb_gmaps_widget .wpb_wrapper {
					padding: 0px;
				}

				.social-share {
					display: block;
					margin-bottom: 20px;
					background-color: $light-warm-gray;
					border-top: 1px solid $warm-gray;
					border-bottom: 1px solid $warm-gray;
					padding: 15px 20px;

					h3 {
						text-align: center;
						color: $warm-gray;
						@include Aleo(20px,normal,600,20px);
						margin-bottom: 15px;
					}
					.social-icons {
						display: block;
						margin: 0 auto;
						width: 100%;
						text-align: center;
					}
					a {
						display: inline-block;
						vertical-align: top;
						text-decoration: none;
						-ms-transition: all 0.35s ease-in-out 0s;
						-webkit-transition: all 0.35s ease-in-out 0s;
						transition: all 0.35s ease-in-out 0s;

						margin: 0 5px;

						&:last-of-type {
							margin-right: 0;
						}
						&:hover {
							-ms-transform: rotate(360deg) scale(1.125,1.125);
							-webkit-transform: rotate(360deg) scale(1.125,1.125);
							transform: rotate(360deg) scale(1.125,1.125);
						}
					}
				}

				.article_about_meta span,
				.event_posting_meta span,
				.article_meta,
				.event_meta {
					font-size: 13px;
					line-height: 20px;
					font-weight: 700;
					color: #666666;

					a {
						color: $green;
						text-decoration: none;
						position: relative;
					}

					a:before {
					  content: "";
					  position: absolute;
					  width: 100%;
					  height: 1px;
					  bottom: -1px;
					  left: 0;
					  background-color: $green;
					  visibility: hidden;
					  -ms-transform: scaleX(0);
					  -webkit-transform: scaleX(0);
					  transform: scaleX(0);
					  -ms-transition: all 0.3s ease-in-out 0s;
					  -webkit-transition: all 0.3s ease-in-out 0s;
					  transition: all 0.3s ease-in-out 0s;
					}

					a:hover:before {
					  visibility: visible;
					  -ms-transform: scaleX(1);
					  -webkit-transform: scaleX(1);
					  transform: scaleX(1);
					}
				}
				.article_meta,
				.event_meta {
					border-top: 1px solid $warm-gray;
					padding-top: 10px;
					position: relative;

					.article_tags,
					.event_tags,
					.article_categories,
					.event_categories {
						display: inline-block;
						vertical-align: top;
						max-width: 35%;
						margin-right: 5%;
					}

					.article_comments,
					.event_comments {
						position: absolute;
						display: block;
						top: 10px;
						right: 0;
						background-image: url(../images/icons/comment_icon.svg);
						background-repeat: no-repeat;
						height: 20px;
						padding-left: 28px;
						color: $orange;

						a {
							color: $orange;
							position: relative;
							top: 1px;
						}

						a:before {
							background-color: $orange;
						}
					}
				}

				//Event Specific Styles
				.event_details {
					clear: both;
				}
				.event_details h2:last-of-type {
					margin-top: 20px;
				}
				h3.event_details_name,
				p.event_details_date_time,
				div.event_details_location_name {
					margin-left: 10px;
				}
				h3.event_details_name {
					@include Aleo(18px,italic,700,22px);
				}
				p.event_details_date_time > span {
					font-style: italic;
					font-weight: 700;
				}
				div.event_details_location_name {
					& > span {
						display: block;
					}
					#event_location_name span,
					#event_address span {
						display: inline;
						font-style: italic;
						font-weight: 700;
					}
				}
				#event_map {
					display: none;
					width: 100%;
					height: 300px;
					margin: 10px 0;
				}
				#map-canvas {
					width: 100%;
					height: 100%;
				}
				.gm-style,
				.gm-style * {
					max-width: initial !important;
				}
				.gm-style-iw > div {
					width: 110% !important;
				}
				.gmap-popup {
				    width:500px;
				}

				.event_meta {
					margin-top: 20px;
				}

				//Review Specific
				.rating p {
					font-weight: 700;
					font-size: 18px;

					.stars {
						display: inline-block;
						position: relative;
						top: 1px;
						color: $orange;
					}
				}
			}

			//Calendar View
			&.calendar {
				.color_bar {
					border-right-color: $green;
				}
			}
			.naaft_cal { display: block; margin: 0 auto; }
			.naaft_cal * { font-size: 0; }
			.naaft_cal > header { text-align: center; position: relative; font-size: 16px; }
			.naaft_cal > header * { font-size: 16px; }
			.naaft_cal > header nav { display: block; }
			.naaft_cal > header nav .back { position: absolute; left: 0; top: 0; }
			.naaft_cal > header nav .forward { position: absolute; right: 0; top: 0; }
			.naaft_cal > nav { display: block; }
			.naaft_cal > nav .back { position: absolute; left: 0; bottom: 0; }
			.naaft_cal > nav .forward { position: absolute; right: 0; bottom: 0; }
			.naaft_cal > section .cell_wrapper {
				display: block;
				position: relative;
			}
			.naaft_cal > section header { display: block; }
			.naaft_cal > section header div.day { display: inline-block; vertical-align: top; text-align: center; width: 14.28571%; font-size: 16px; }
			.naaft_cal > section .row { display: block; }
			.naaft_cal > section .row div.date { display: inline-block; vertical-align: top; width: 14.28571%; font-size: 16px; height: 100%; position: relative; }
			.naaft_cal > section .row div.date * { font-size: 16px; }

			.home .naaft_cal { 
				width: 89.8850574713%; 
				font-family: "Aleo", Georgia, Rockwell, sans-serif;
				margin-bottom: 40px;
			}
			.naaft_cal > header h3 { 
				@include Tablet(36px,normal,700,40px);
				color: $brown;
				margin-bottom: 30px;

				span {
					@include Tablet(36px,normal,700,40px);
					color: $brown;
				}

				span:last-of-type {
					margin-left: 5px;
				}
			}
			.naaft_cal > header nav a,
			.naaft_cal > nav a {
				@include Tablet(15px,normal,700,20px);
				color: #ffffff;
				letter-spacing: 0.05em;
				display: inline-block;
				background-color: $orange;
				text-transform: uppercase;
				text-decoration: none;
				padding: 2px 9px 4px;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px; 
				-webkit-transition: background-color 0.5s ease;
				-moz-transition: 	background-color 0.5s ease;
				-o-transition: 		background-color 0.5s ease;
				transition: 		background-color 0.5s ease;

				span {
					font-size: 15px;
					position: relative;
					top: -1px;
				}

				&:hover {
					background-color: darken($orange, 10%);
				}
			}
			.naaft_cal > header nav .back { top: 28px; }
			.naaft_cal > header nav .back span { margin-right: 5px; }
			.naaft_cal > header nav .forward { top: 28px; }
			.naaft_cal > header nav .forward span { margin-left: 5px; }
			.naaft_cal > nav .back { left: 3.5%; bottom: 20px; }
			.naaft_cal > nav .back span { margin-right: 5px; }
			.naaft_cal > nav .forward { right: 3.5%; bottom: 20px; }
			.naaft_cal > nav .forward span { margin-left: 5px; }
			.naaft_cal > section { margin-bottom: 45px; }
			.naaft_cal > section header { 
				background-color: $warm-gray;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;
				margin-bottom: 10px;
			}
			.naaft_cal > section header div.day { 
				padding: 6px 0; 
				@include Aleo(16px,normal,700,21px);
				border-right: 1px solid #F4F1ED; 
				color: #F4F1ED;
			}
			.naaft_cal > section header div.day:first-of-type { 
				-webkit-border-top-left-radius: 3px;
				-moz-border-radius-topleft: 3px;
				border-top-left-radius: 3px;
			}
			.naaft_cal > section header div.day:last-of-type { 
				border-right: none;
				-webkit-border-top-right-radius: 3px;
				-moz-border-radius-topright: 3px;
				border-top-right-radius: 3px;
			}
			.naaft_cal > section .row { 
				min-height: 80px;
				border-bottom: 1px solid $warm-gray; 
			}
			.naaft_cal > section .row:first-of-type {
				border-top: 1px solid $warm-gray;
			}
			.naaft_cal > section .row div.date { 
				min-height: 120px;
				padding: 40px 8px 0;
			}
			.naaft_cal > section .row div.date span.date_num { 
				position: absolute; 
				display: block; 
				top: 5px; 
				left: 8px; 
				text-decoration: none; 
				padding: 2px 6px 1px;
				color: #333333;
				@include Aleo(16px,normal,700,21px);
			}
			.naaft_cal > section .row div.date a.calendar_event {
				font-size: 14px;
				font-weight: 700;
				line-height: 110%;
				display: block;
				width: 100%;
				padding: 10px;
				background-color: $green;
				color: #ffffff;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;
				margin-bottom: 10px;
				text-decoration: none;
			}
			.naaft_cal > section .row div.date.inactive { background-color: #F4F1ED; }
			.naaft_cal > section .row div.date.today span.date_num { 
				background-color: $green;
				color: #ffffff;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;
			}
			.naaft_cal > section .row:last-of-type div.date { border-bottom: 1px solid #dddddd; }
			.naaft_cal > section .vr {
				display: block;
				position: absolute;
				top: 0;
				width: 1px;
				height: 100%;
				background-color: $warm-gray;
			}
			.naaft_cal > section .vr.pos_0 {
				left: 0;
			}
			.naaft_cal > section .vr.pos_1 {
				left: 14.28571%;
			}
			.naaft_cal > section .vr.pos_2 {
				left: 28.57142%;
			}
			.naaft_cal > section .vr.pos_3 {
				left: 42.85713%;
			}
			.naaft_cal > section .vr.pos_4 {
				left: 57.14284%;
			}
			.naaft_cal > section .vr.pos_5 {
				left: 71.42855%;
			}
			.naaft_cal > section .vr.pos_6 {
				left: 85.71426%;
			}
			.naaft_cal > section .vr.pos_7 {
				left: 99.99997%;
			}
		}

		//Gravity Form
		.main_column .gform_wrapper {
			.gsection .gfield_label, 
			h2.gsection_title, 
			h3.gform_title {
				@include Tablet(28px,normal,700,34px);
				color: $brown;
				display: block;
				border-bottom: 1px solid $warm-gray;
				padding-bottom: 10px !important;
			}
			.gsection {
				border: none;
				margin: 50px 0 0;

				&:first-of-type {
					margin-top: 0;
				}
			}
			.gfield_required {
				color: $orange;
				position: relative;
				top: 3px;
			}
			input,
			input[type=text], 
			input[type=url], 
			input[type=email], 
			input[type=tel], 
			input[type=number], 
			input[type=password],
			textarea {
				@include Aleo(18px,normal,400,23px);
				display: block;
				border: 1px solid $warm-gray;
				background-color: #F9F9F9;
				padding: 8px 12px;
				color: #333333;
			}
			.chosen-container {
				@include Aleo(18px,normal,400,23px);

				.chosen-results li {
					margin-bottom: 4px;
					padding: 10px;
					width: 98%;
				}
			}
			.chosen-container-single {
				.chosen-single {
					padding: 7px 0 0 12px;
					height: 40px;

					div b {
						background-position: 0px 10px;
					}
				}

				.chosen-search input[type=text] {
					font-size: 12px;
				}
			}
			.chosen-container-active.chosen-with-drop .chosen-single div b {
				background-position: -16px 9px;
			}
			.top_label .gfield_label {
				font-style: italic;
			}
			.gfield_description {
				@include Aleo(13px,italic,400,23px);
			}
			ul.gfield_checkbox, 
			ul.gfield_radio {
				display: block;
				margin-left: 5px !important;
				margin-top: 10px !important;

				input, label {
					cursor: pointer;
				}
				input[type=checkbox] {
					width: 1.1em !important;
					height: 1.1em !important;
					margin-right: 10px !important;
				}

				input[type="radio"]+label, 
				input[type="checkbox"]+label {
					font-size: 18px;
				}
			}
			.gform_footer {
				padding-bottom: 0;
			}
			.gform_footer input[type=submit] {
				border: none;
				color:#ffffff;
				font-weight: bold;
				font-size: 18px;
				padding: 8px 20px;
				cursor: pointer;
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				border-radius: 5px;
				-webkit-transition: background-color 0.5s ease;
				-moz-transition: background-color 0.5s ease;
				-o-transition: background-color 0.5s ease;
				transition: background-color 0.5s ease;
				background-color: $green;

				&:hover {
					background-color: darken( $green, 5% );
				}
			}

			//Errors
			div.validation_error {
				font-size: 15px;
				text-align: center;
				background-color: $red;
				padding: 10px 20px;
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				border-radius: 5px;
				color: #ffffff;
			}
			li.gfield.gfield_error, 
			li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
				border-color: $red;
				background-color: lighten($red, 40%);
			}
			.validation_message {
				font-style: italic;
				color: darken($red, 20%);
			}
		}

		//Team Members
		.main_column .team-member {
			.team-member-img {
				display: block;
				width: 100%;
				height: auto;
			}
			.team-member-name {
				display: block;
				margin: 13px 0 0;
				text-decoration: none;

				h2 {
					display: inline-block;
					color: $green;
					@include Aleo(18px,normal,600,24px);
					border: none;
					padding: 0;
					margin: 0;
					position: relative;
				}

				h2:before {
				  content: "";
				  position: absolute;
				  width: 100%;
				  height: 1px;
				  bottom: -1px;
				  left: 0;
				  background-color: $green;
				  visibility: hidden;
				  -ms-transform: scaleX(0);
				  -webkit-transform: scaleX(0);
				  transform: scaleX(0);
				  -ms-transition: all 0.3s ease-in-out 0s;
				  -webkit-transition: all 0.3s ease-in-out 0s;
				  transition: all 0.3s ease-in-out 0s;
				}

				h2:hover:before {
				  visibility: visible;
				  -ms-transform: scaleX(1);
				  -webkit-transform: scaleX(1);
				  transform: scaleX(1);
				}
			}
			h3 {
				display: block;
				color: $brown;
				@include Aleo(16px,italic,400,24px);
				padding-bottom: 5px;
				border-bottom: 1px solid $warm-gray;
				margin: 0 0 10px;

				b {
					font-weight: 600;
				}
			}

			p {
				padding-bottom: 10px;
				border-bottom: 1px solid $warm-gray;
			}
		}

	//Main Column Widgets
		.main_column .widgets_area {
			display: block;
			width: 100%;
			position: relative;
			margin-bottom: 30px;

			.yarpp-related-widget {

				& > h2 {
					@include Tablet(36px,normal,700,44px);
					color: #ffffff;
					padding-bottom: 2px;
					border-bottom: 1px solid #ffffff;
					margin-bottom: 20px;
					text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);
				}
				& > p {
					@include Aleo(14px,normal,400,22px);
					color: darken($warm-gray, 10%);

					&.nomore {
						@include Aleo(14px,normal,700,16px);
						color: #ffffff;
						text-align: center;
						height: 46px;
						text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);
					}
				}
			}

			.yarpp-related-widget .widgets_area {
				font-size: 0;
			}
			.yarpp-related-widget .widget {
				display: inline-block;
				vertical-align: top;
				width: 32%;
				margin-right: 2%;
				background: #ffffff;
				padding: 2%;
				-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
				-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
				box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				border-radius: 5px;
				margin-bottom: 30px;
				position: relative;
				overflow: hidden;

				&:last-of-type {
					margin-right: 0;
				}

				h3 {
					@include Tablet(28px,normal,700,36px);
					color: $brown;
					margin-bottom: 10px;
				}

				img {
					display: block;
					width: 100%;
					height: auto;
					border: 1px solid $brown;
				}

				.color_bar {
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					width: 0; 
					height: 0; 
					border-top: 0px solid transparent;
					border-bottom: 12px solid transparent;
					border-right-style: solid;
					border-right-color: $orange;
					border-right-width: 0;
				}

				p {
					@include Aleo(14px,normal,200,22px);
					color: #333333;
					display: block;
					margin: 10px 0;
				}

				a.see_more {
					display: inline-block;
					@include Tablet(15px,normal,600,21px);
					letter-spacing: 0.05em;
					text-transform: uppercase;
					background-color: $orange;
					padding: 2px 9px 4px;
					text-decoration: none;
					color: #ffffff;
					-webkit-border-radius: 3px;
					-moz-border-radius: 3px;
					border-radius: 3px;
					-webkit-transition: background-color 0.5s ease;
					-moz-transition: 	background-color 0.5s ease;
					-o-transition: 		background-color 0.5s ease;
					transition: 		background-color 0.5s ease;
				}

				a.see_more:hover {
					background-color: darken( $orange, 5% ) 
				}
			}

			.widget.recent_posts,
			.widget.upcoming_events {
				display: inline-block;
				vertical-align: top;
				width: 48%;
				background: #ffffff;
				padding: 1% 2.5% 4% 2.5%;
				-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
				-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
				box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				border-radius: 5px;
				margin-bottom: 30px;
				position: relative;
				overflow: hidden;

				h3 {
					@include Tablet(36px,normal,700,44px);
					color: $brown;
					margin-bottom: 10px;
				}
				.color_bar {
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					width: 0; 
					height: 0; 
					border-top: 0px solid transparent;
					border-bottom: 12px solid transparent;
					border-right-style: solid;
					border-right-color: $orange;
					border-right-width: 0;
				}

				article {
					display: block;
					margin: 0;
					padding: 0;
					background-color: transparent;
					overflow: auto;
					-webkit-box-shadow: none;
					-moz-box-shadow: none;
					box-shadow: none;
					-webkit-border-radius: 0;
					-moz-border-radius: 0;
					border-radius: 0;
					border-bottom: 1px solid $warm-gray;

					padding-bottom: 12px;
					margin-bottom: 12px;

					&:last-of-type {
						border-bottom: none;
					}

					.featured_image,
					.event_date,
					.post_meta {
						display: inline-block;
						vertical-align: top;
					}
					h4 {
						@include Aleo(18px,normal,700,24px);
						margin-bottom: 5px;
						a {
							color: #333333;
							text-decoration: none;
							position: relative;
						}
						a:before {
							content: "";
							position: absolute;
							width: 100%;
							height: 1px;
							bottom: -1px;
							left: 0;
							background-color: #333333;
							visibility: hidden;
							-ms-transform: scaleX(0);
							-webkit-transform: scaleX(0);
							transform: scaleX(0);
							-ms-transition: all 0.3s ease-in-out 0s;
							-webkit-transition: all 0.3s ease-in-out 0s;
							transition: all 0.3s ease-in-out 0s;
						}

						a:hover:before {
						  visibility: visible;
						  -ms-transform: scaleX(1);
						  -webkit-transform: scaleX(1);
						  transform: scaleX(1);
						}
					}
					& > .featured_image,
					& > .event_date {
						width: 30%;
						margin-right: 5%;

						img {
							width: 100%;
							height: auto;
							border: none;
							margin: 0;
							-webkit-border-radius: 3px;
							-moz-border-radius: 3px;
							border-radius: 3px;
						}
					}
					& > .event_date {
						a {
							display: block;
							width: 100%;
							height: 100%;
							text-transform: uppercase;
							color: #ffffff;
							text-decoration: none;
							background-color: $orange;
							padding: 5px 0;
							-webkit-border-radius: 5px;
							-moz-border-radius: 5px;
							border-radius: 5px;
							-webkit-transition: background-color 0.5s ease;
							-moz-transition: 	background-color 0.5s ease;
							-o-transition: 		background-color 0.5s ease;
							transition: 		background-color 0.5s ease;
						}
						a:hover {
							background-color: darken($orange, 5%);
						}
						span {
							display: block;
							text-align: center;
						}
						span.event_month {
							@include Tablet(30px,normal,700,30px);
						}
						span.event_day {
							@include Tablet(42px,normal,700,42px);
							position: relative;
							top: -3px;
						}
					}
					.post_meta {
						width: 65%;
						p {
							margin-bottom: 0;
						}
					}
					.post_meta.no_featured {
						width: 100%;
					}
				}

				a.all_post_link {
					display: block;
					width: 100%;
					text-align: center;
					padding: 8px 0;
					@include Aleo(15px,normal,700,22px);
					color: #ffffff;
					background-color: $yellow;
					text-decoration: none;
					position: absolute;
					bottom: 0;
					left: 0;
					-webkit-transition: background-color 0.5s ease;
					-moz-transition: 	background-color 0.5s ease;
					-o-transition: 		background-color 0.5s ease;
					transition: 		background-color 0.5s ease;
				}
				a.all_post_link:hover {
					background-color: darken($yellow, 10%);
				}
			}
			.widget.upcoming_events {
				margin-right: 4%;
			}
		}

	//Comments
		#comments {
			h2 {
				@include Tablet(36px,normal,700,44px);
				color: #ffffff;
				padding-bottom: 2px;
				border-bottom: 1px solid #ffffff;
				margin-bottom: 20px;
			}

			p.nocomments {
				@include Aleo(14px,normal,400,22px);
				color: darken($warm-gray, 10%);
			}

			.commentlist > li {
				margin-bottom: 30px;
				background-color: #ffffff;
				padding: 2%;
				@include Aleo(15px,normal,200,22px);
				color: #333333;
				-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
				-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
				box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				border-radius: 5px;
			}
			.commentlist li:last-of-type {
				margin-bottom: 30px;
			}
			.commentlist section {
				margin-bottom: 20px;

				h3 {
					@include Aleo(20px,normal,600,22px);
				}
				.comment-date {
					@include Aleo(14px,italic,400,20px);
					color: #808080;
				}

				.comment-author-image,
				.comment-author-meta {
					display: inline-block;
					vertical-align: middle;
				}

				.comment-author-image {
					margin-right: 10px;

					img {
						-webkit-border-radius: 30px;
						-moz-border-radius: 30px;
						border-radius: 30px;
						border: 1px solid $warm-gray;
					}
				}
				.comment-onhold {
					display: block;
					margin-left: 72px;
					font-weight: 400;
					font-style: italic;
					color: $yellow;
					background-color: $orange;
					padding: 3px 10px;
					margin-bottom: 8px;
					-webkit-border-radius: 3px;
					-moz-border-radius: 3px;
					border-radius: 3px;
				}
				p {
					display: inline;
				}
				.comment-edit-link {
					display: inline;
					color: $green;
					text-decoration: none;
					position: relative;
				}
				.comment-edit-link:before {
				  content: "";
				  position: absolute;
				  width: 100%;
				  height: 1px;
				  bottom: -1px;
				  left: 0;
				  background-color: $green;
				  visibility: hidden;
				  -ms-transform: scaleX(0);
				  -webkit-transform: scaleX(0);
				  transform: scaleX(0);
				  -ms-transition: all 0.3s ease-in-out 0s;
				  -webkit-transition: all 0.3s ease-in-out 0s;
				  transition: all 0.3s ease-in-out 0s;
				}
				.comment-edit-link:hover:before {
				  visibility: visible;
				  -ms-transform: scaleX(0);
				  -webkit-transform: scaleX(1);
				  transform: scaleX(1);
				}
				.comment-body,
				.comment-reply {
					display: block;
					margin-left: 72px;
				}
				.comment-reply {
					margin-top: 8px;
				}
				.comment-reply a {
					display: inline-block;
					@include Tablet(15px,normal,600,21px);
					letter-spacing: 0.05em;
					text-transform: uppercase;
					background-color: $orange;
					padding: 2px 9px 4px;
					text-decoration: none;
					color: #ffffff;
					-webkit-border-radius: 3px;
					-moz-border-radius: 3px;
					border-radius: 3px;
					-webkit-transition: background-color 0.5s ease;
					-moz-transition: 	background-color 0.5s ease;
					-o-transition: 		background-color 0.5s ease;
					transition: 		background-color 0.5s ease;
				}

				.comment-reply a:hover {
					background-color: darken( $orange, 5% ) 
				}
			}
			.commentlist .children {
				display: block;
				margin-left: 72px;

				li {
					padding-top: 10px;
					border-top: 1px solid $warm-gray;
				}
			}

			//Comment Reply Module
			#respond {
				& > h3 {
					text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);
				}
				h3 {
					@include Tablet(36px,normal,700,44px);
					color: #ffffff;
					padding-bottom: 2px;
					border-bottom: 1px solid #ffffff;
					margin-bottom: 20px;
					position: relative;

					small a {
						position: absolute;
						top: 8px;
						right: 0;
						display: inline-block;
						@include Tablet(15px,normal,600,24px);
						letter-spacing: 0.05em;
						text-transform: uppercase;
						background-color: $red;
						padding: 2px 9px 4px;
						text-decoration: none;
						color: #ffffff;
						border: none;
						-webkit-border-radius: 3px;
						-moz-border-radius: 3px;
						border-radius: 3px;
						-webkit-transition: background-color 0.5s ease;
						-moz-transition: 	background-color 0.5s ease;
						-o-transition: 		background-color 0.5s ease;
						transition: 		background-color 0.5s ease;
						cursor: pointer;
						margin: 0;
						width: 100px;
						text-align: center;

						&:hover {
							background-color: darken( $red, 10% );
						}
					}
				}

				form {
					background-color: #ffffff;
					padding: 2.5%;
					@include Aleo(15px,normal,200,22px);
					color: #333333;
					-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
					-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
					box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
					-webkit-border-radius: 5px;
					-moz-border-radius: 5px;
					border-radius: 5px;
					margin-bottom: 20px;
					position: relative;

					input {
						display: block;
						width: 90%;
						border: 1px solid $warm-gray;
						background-color: #F9F9F9;
						margin-bottom: 20px;
						@include Aleo(18px,normal,400,23px);
						padding: 8px 12px;
						color: #333333;

						&::-webkit-input-placeholder {
						   @include Aleo(18px,italic,200,23px);
						   color: #999999;
						}

						&:-moz-placeholder { /* Firefox 18- */
						   @include Aleo(18px,italic,200,23px);
						   color: #999999;  
						}

						&::-moz-placeholder {  /* Firefox 19+ */
						   @include Aleo(18px,italic,200,23px);
						   color: #999999;  
						}

						&:-ms-input-placeholder {  
						   @include Aleo(18px,italic,200,23px);
						   color: #999999;  
						}
					}
					textarea {
						display: block;
						width: 100%;
						min-width: 100%;
						max-width: 100%;
						border: 1px solid $warm-gray;
						background-color: #F9F9F9;
						margin-bottom: 30px;
						@include Aleo(18px,normal,400,23px);
						padding: 8px 12px;
						color: #333333;
						min-height: 200px;

						&::-webkit-input-placeholder {
						   @include Aleo(18px,italic,200,23px);
						   color: #999999;
						}

						&:-moz-placeholder { /* Firefox 18- */
						   @include Aleo(18px,italic,200,23px);
						   color: #999999;  
						}

						&::-moz-placeholder {  /* Firefox 19+ */
						   @include Aleo(18px,italic,200,23px);
						   color: #999999;  
						}

						&:-ms-input-placeholder {  
						   @include Aleo(18px,italic,200,23px);
						}
					}

					.logged-in-as {
						display: inline-block;
						vertical-align: middle;
						margin-right: 10px;
						margin-bottom: 10px;

						img {
							-webkit-border-radius: 30px;
							-moz-border-radius: 30px;
							border-radius: 30px;
							display: block;
							border: 1px solid $warm-gray;
						}
					}
					.comment-reply-author-meta {
						display: inline-block;
						vertical-align: middle;
						margin-bottom: 10px;

						h3 {
							display: block;
							@include Aleo(20px,normal,600,22px);
							border-bottom: none;
							margin: 0;

							a {
								color: $green;
								text-decoration: none;
								position: relative;
							}
						}

						& > a {
							color: $orange;
							text-decoration: none;
							position: relative;
						}

						a:before {
						  content: "";
						  position: absolute;
						  width: 100%;
						  height: 1px;
						  bottom: -1px;
						  left: 0;
						  background-color: $green;
						  visibility: hidden;
						  -ms-transform: scaleX(0);
						  -webkit-transform: scaleX(0);
						  transform: scaleX(0);
						  -ms-transition: all 0.3s ease-in-out 0s;
						  -webkit-transition: all 0.3s ease-in-out 0s;
						  transition: all 0.3s ease-in-out 0s;
						}
						& > a:before {
							background-color: $orange;
							bottom: -2px;
						}

						a:hover:before {
						  visibility: visible;
						  -ms-transform: scaleX(1);
						  -webkit-transform: scaleX(1);
						  transform: scaleX(1);
						}
					}
				}

				.form-submit {
					display: block;
					width: 80px;
					position: absolute;
					right: 2.5%;
					bottom: 3.5%;
					z-index: 1;

					input {
						display: block;
						@include Tablet(24px,normal,600,30px);
						letter-spacing: 0.05em;
						text-transform: uppercase;
						background-color: $orange;
						padding: 2px 9px 4px;
						text-decoration: none;
						color: #ffffff;
						border: none;
						-webkit-border-radius: 3px;
						-moz-border-radius: 3px;
						border-radius: 3px;
						-webkit-transition: background-color 0.5s ease;
						-moz-transition: 	background-color 0.5s ease;
						-o-transition: 		background-color 0.5s ease;
						transition: 		background-color 0.5s ease;
						cursor: pointer;
						margin: 0;
						width: 100%;

						&:hover {
							background-color: darken( $orange, 5% ) 
						}
					}
				}

				.comment-form-subscriptions {
					@include Aleo(16px,italic,200,22px);
					color: #333333;
					position: relative;
					top: -3px;

					label {
						cursor: pointer;
					}
				}

				#subscribe-reloaded {
					display: inline-block;
					width: auto;
					margin: 0;
					margin-right: 8px;
					cursor: pointer;
				}
			}

			.commentlist #respond {
				border-top: 1px solid $warm-gray;
				padding: 20px 10px 0;
				margin-top: 10px;

				h3 {
					text-shadow: none;
					color: $warm-gray;
				}

				form {
					-webkit-box-shadow: none;
					-moz-box-shadow: none;
					box-shadow: none;
					border: 1px solid lighten($warm-gray, 10%);
				}
			}
		}

	//Right Column
		.right_column {
			display: inline-block;
			vertical-align: top;
			width: 24%;
			z-index: 100;
			position: relative;
		}

	//Widgets
		.site_wrapper > .left_column .widget,
		.site_wrapper > .right_column .widget {
			display: block;
			width: 100%;
			background: #ffffff;
			padding: 2% 6%;
			-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
			-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			border-radius: 5px;
			margin-bottom: 30px;
			position: relative;
			overflow: hidden;

			h3 {
				@include Tablet(36px,normal,700,44px);
				color: $brown;
				margin-bottom: 10px;
			}

			.post_content {
				img {
					display: block;
					width: 100%;
					height: auto;
				}
			}

			.color_bar {
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				width: 0; 
				height: 0; 
				border-top: 0px solid transparent;
				border-bottom: 12px solid transparent;
				border-right-style: solid;
				border-right-color: $orange;
				border-right-width: 0;
			}

			&.recipe_of_month,
			&.tip_of_week,
			&.deal_of_week,
			&.sales_flyer,
			&.staff_picks {

				a {
					display: block;
				}
				.post_content.featured-image-has-border img {
					border: 1px solid $brown;
				}
				img {
					width: 100%;
					height: auto;
				}

				h4 {
					margin-top: 10px;
					@include Aleo(18px,normal,700,24px);
					color: #333333;

					a {
						color: $green;
						text-decoration: none;
						position: relative;
					}

					a:before {
						content: "";
						position: absolute;
						width: 100%;
						height: 1px;
						bottom: -1px;
						left: 0;
						background-color: #94d500;
						visibility: hidden;
						-ms-transform: scaleX(0);
						-webkit-transform: scaleX(0);
						transform: scaleX(0);
						-ms-transition: all 0.3s ease-in-out 0s;
						-webkit-transition: all 0.3s ease-in-out 0s;
						transition: all 0.3s ease-in-out 0s;
					}

					a:hover:before {
					  visibility: visible;
					  -ms-transform: scaleX(1);
					  -webkit-transform: scaleX(1);
					  transform: scaleX(1);
					}
				}

				p {
					@include Aleo(15px,normal,200,22px);
					color: #333333;
				}

				blockquote {
					display: block;
					margin: 10px 0;
					padding: 5px 8px;
					background-color: $light-warm-gray;
					border-left: 6px solid $med-warm-gray;
					@include Aleo(15px,italic,200,22px);
				}

				a.see_more {
					display: inline-block;
					@include Tablet(15px,normal,600,21px);
					letter-spacing: 0.05em;
					text-transform: uppercase;
					background-color: $orange;
					padding: 2px 9px 4px;
					text-decoration: none;
					color: #ffffff;
					-webkit-border-radius: 3px;
					-moz-border-radius: 3px;
					border-radius: 3px;
					-webkit-transition: background-color 0.5s ease;
					-moz-transition: 	background-color 0.5s ease;
					-o-transition: 		background-color 0.5s ease;
					transition: 		background-color 0.5s ease;
					margin-top: 10px;
					margin-bottom: 10px;
				}

				a.see_more:hover {
					background-color: darken( $orange, 5% ) 
				}
			}

			&.deal_of_week h4,
			&.sales_flyer h4 {
				margin-bottom: 10px;
			}
			&.staff_picks ul{
				position: relative;
				display: block;
				min-height: 190px;
				margin-bottom: 10px;
				li {
					display: none;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;

					img {
						max-width: 100%;
						width: auto;
						margin: 0 auto;
					}
				}
				li:first-of-type {
					display: block;
				}
			}
		}
		.site_wrapper > .right_column .widget {
			top: -54px;
		}
		.site_wrapper > .left_column .widgets_area {
			padding-left: 20px;
		}
		.site_wrapper > .left_column .widget {
			margin-top: 30px;
		}
		.fb_iframe_widget {
			width: 100%;
			border-top: 1px solid $warm-gray;
			span {
				width: 100% !important;

				iframe {
					width: 100% !important;
				}
			}
		}

	//Footer
		footer {
			background-image: url(../images/tex/burlap.jpg);
			display: block;
			width: 100%;
			padding-top: 25px;
			padding-bottom: 30px;
			-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
			-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.6);

			.left_column {
				position: inherit;
				padding: 0 10px;

				.sunrise_footer_logo {
					width: 100%;
					display: block;
					padding-bottom: 15px;
					border-bottom: 1px solid rgba(0,0,0,0.3);

					& * {
						width: 100%;
						height: auto;
					}
				}
			}

			.copyright {
				display: block;
				border-top: 1px solid rgba(255,255,255,0.1);
				padding-top: 10px;
				text-align: center;
				@include Aleo(14px,normal,700,20px);
				color: rgba(255,255,255,0.4);
				text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.1);
			}

			.main_column {
				margin: 0;
				position: inherit;
				padding-top: 2.3%;
				width: 75.3125%;

				.widget {
					display: inline-block;
					vertical-align: top;
					margin-bottom: 20px;

					h3 {
						@include Tablet(20px,normal,600,20px);
						text-transform: uppercase;
						letter-spacing: 0.01em;
						margin-bottom: 10px;
					}
					h3 a {
						@include Tablet(20px,normal,600,20px);
						text-transform: uppercase;
						letter-spacing: 0.01em;
						text-decoration: none;
					}
					ul li {
						display: block;
						padding-bottom: 5px;
					}
					ul li a,
					address {
						@include Aleo(14px,normal,400,18px);
						color: rgba(255,255,255,0.75);
						text-decoration: none;
						-webkit-transition: color 0.5s ease;
						-moz-transition: 	color 0.5s ease;
						-o-transition: 		color 0.5s ease;
						transition: 		color 0.5s ease;

						&:hover {
							color: rgba(255,255,255,1);
						}
					}

					&:nth-child(1),
					&:nth-child(5) {
						width: 17%;
						margin-left: 16%;	
					}
					&:nth-child(2),
					&:nth-child(6) {
						width: 20%;
					}
					&:nth-child(3) {
						width: 32%;
					}
					&:nth-child(4) {
						width: 15%;	
					}
					&:nth-child(7) {
						width: 17%;	
					}
					&:nth-child(8) {
						width: 15%;	
					}

					//Widget Specific
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4),
					&:nth-child(5),
					&:nth-child(6) {
						h3 {
							color: $orange;
						}
						h3 a {
							color: $orange;
						}
					}
					&:nth-child(7),
					&:nth-child(8) {
						h3 {
							color: $yellow;
						}
						h3 a {
							color: $yellow;
						}
					}
					.menu-footer-store-tour-container ul {
						-webkit-columns: 10px 2;
						-moz-columns: 10px 2;
						columns: 10px 2;
						-webkit-column-gap: 20px;
						-moz-column-gap: 20px;
						column-gap: 20px;

						li {
							break-inside: avoid-column;
						}
					}

					address {
						display: inline-block;
						vertical-align: top;
					}
					address {
						width: 138px;
						margin-right: 20px;
						margin-bottom: 15px;

						i {
							display: block;
							margin-bottom: 5px;
							font-style: italic;

							a {
								color: $green;
							}
						}
					}
					.social-media {
						display: block;
					}
					.social-media a {
						display: inline-block;
						vertical-align: top;
						text-indent: -9999px;
						margin-right: 10px;
						-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
						-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
						box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
					}
					#facebook {
						width: 20px;
						height: 20px;
						background-image: url(../images/icons/facebook.svg);
					}
					#twitter {
						width: 20px;
						height: 20px;
						background-image: url(../images/icons/twitter.svg);
					}
					#youtube {
						width: 28.4px;
						height: 20px;
						background-image: url(../images/icons/youtube.svg);
						-webkit-border-radius: 5px;
						-moz-border-radius: 5px;
						border-radius: 5px;
					}
					#pinterest {
						width: 22px;
						height: 22px;
						background-image: url(../images/icons/pinterest.svg);
						-webkit-border-radius: 11px;
						-moz-border-radius: 11px;
						border-radius: 11px;
					}
				}
			}
		}

/*::: RESPONSIVE :::*/
@media (max-width: 1023px) {
	#menu_wrapper.mp-pusher {
		left: 0;
		height: 100%;
		overflow: visible;
	}
	#menu_wrapper > #main_nav {
		display: block;
		width: 300px;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1000;
		background-image: url(../images/tex/burlap.jpg);
		background-repeat: repeat;
		-webkit-box-shadow: 5px 0px 12px 0px rgba(0,0,0,0.4);
		-moz-box-shadow: 5px 0px 12px 0px rgba(0,0,0,0.4);
		box-shadow: 5px 0px 12px 0px rgba(0,0,0,0.4);
		-webkit-transform: translate3d(-100%, 0, 0);
		-moz-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);

		#sunrise_main_logo {
			img {
				clip-path: none !important;
				-webkit-clip-path: none !important;
			}

			svg {
				display: none;
			}
		}

		ul.main {
			position: relative;
			height: -webkit-calc(100% - 126px);
			height: -moz-calc(100% - 126px);
			height: calc(100% - 126px);
			overflow: hidden;

			& > li:last-of-type {
				margin-bottom: 80px;
			}
		}

		ul {
			background: none;
			box-shadow: none;

			li {
				position: initial;

				a {
					position: relative;
					-webkit-transition: color 0.5s ease, background-color 0.5s ease;
					-moz-transition: 	color 0.5s ease, background-color 0.5s ease;
					-o-transition: 		color 0.5s ease, background-color 0.5s ease;
					transition: 		color 0.5s ease, background-color 0.5s ease;
				}
				a:hover {
					background-color: rgba(0,0,0,0.2);
				}
				a.mp-back:hover {
					background-color: rgba(0,0,0,0.5);
				}
				&:last-of-type a {
					border-bottom: 2px solid rgba(0,0,0,0.5);
				}
				&.has-children > a:before {
					content: $fa-var-angle-left;
					font: normal normal normal 22px/1 FontAwesome;
					position: absolute;
					left: 15px;
					top: 17px;
				}

				ul.sub-menu {
					display: block;
					position: initial;
					z-index: initial;
					padding: 0;
					margin: 0 0 80px;

					li {
						display: list-item;
						background-color: transparent;
						a {
							display: block;
							text-align: center;
							border-top: 2px solid rgba(0,0,0,0.5);
							color: rgba(255,255,255,0.7);
							@include Tablet(26px,normal,600,50px);
							letter-spacing: 0.01em;
							text-decoration: none;
							text-transform: uppercase;
							padding: 0;
							padding-bottom: 10px;
							-webkit-transition: color 0.5s ease, background-color 0.5s ease;
							-moz-transition: 	color 0.5s ease, background-color 0.5s ease;
							-o-transition: 		color 0.5s ease, background-color 0.5s ease;
							transition: 		color 0.5s ease, background-color 0.5s ease;

							&:hover {
								background-color: rgba(0,0,0,0.2);
								color: rgba(255,255,255,1);
							}
						}
					}
					li.parent-link {
						a {
							color: $orange;

							&:hover {
								color: lighten($orange, 15%);
							}
						}
					}
				}
			}
		}
	}
	.mp-level {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-webkit-transform: translate3d(-100%, 0, 0);
		-moz-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
		background-image: url(../images/tex/burlap.jpg);
		background-repeat: repeat;
		overflow: hidden;
	}
	.mp-pusher::after,
	.mp-level::before {
		position: absolute;
		top: 0;
		right: 0;
		width: 0;
		height: 0;
		content: '';
		opacity: 0;
	}
	.mp-level::after {
		position: absolute;
		top: 150px;
		right: 0;
		width: 0;
		height: 0;
		content: '';
		opacity: 0;
	}
	.mp-pusher::after,
	.mp-level::after {
		background: rgba(0,0,0,0.3);
		-webkit-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
		-moz-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
		transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
	}
	.mp-pusher.mp-pushed::after {
		width: 100%;
		height: 100%;
		opacity: 1;
		-webkit-transition: opacity 0.3s;
		-moz-transition: opacity 0.3s;
		transition: opacity 0.3s;
	}
	.mp-level.mp-level-overlay::after {
		width: 100%;
		height: -webkit-calc(100% - 150px);
		height: -moz-calc(100% - 150px);
		height: calc(100% - 150px);
		opacity: 1;
		-webkit-transition: opacity 0.3s;
		-moz-transition: opacity 0.3s;
		transition: opacity 0.3s;
	}
	.mp-pusher.mp-pushed::after {
		z-index: 999;
	}
	.mp-level.mp-level-overlay {
		cursor: pointer;
	}
	.mp-pusher,
	.mp-level {
		-webkit-transition: all 0.5s;
		-moz-transition: all 0.5s;
		transition: all 0.5s;
	}
	.mp-overlap .mp-level.mp-level-open {
		box-shadow: 1px 0 2px rgba(0,0,0,0.2);
		-webkit-transform: translate3d(-40px, 0, 0);
		-moz-transform: translate3d(-40px, 0, 0);
		transform: translate3d(-40px, 0, 0);
	}
	.mp-menu > .mp-level,
	.mp-menu > .mp-level.mp-level-open,
	.mp-menu.mp-overlap > .mp-level,
	.mp-menu.mp-overlap > .mp-level.mp-level-open {
		box-shadow: none;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	.mp-cover .mp-level.mp-level-open {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		z-index: 1;
	}
	.mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
		-webkit-transform: translate3d(-100%, 0, 0);
		-moz-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
	#main_nav .mp-back {
		display: block;
		background-color: rgba(0,0,0,0.3);

		&:after {
			content: $fa-var-angle-right;
			font: normal normal normal 22px/1 FontAwesome;
			position: absolute;
			right: 15px;
			top: 17px;
		}
	}

	.hamburger {
		display: inline-block;
	}
	#sunrise_main_logo_responsive {
		display: block;
		height: 150px;
		background-image: url(../images/tex/burlap.jpg);
		padding-bottom: 6px;

		img {
			width: auto;
			height: 100%;
			display: block;
			margin: 0 auto;
		}
	}
	.header_tab {
		left: 0;
		right: 0;
		top: 150px;
		margin: 0 auto;
		width: 408px;
	}
	.site_wrapper > .left_column {
		position: relative;
		display: block;
		width: 100%;
		margin-bottom: 30px;
		top: 0 !important;

		#main_nav {
			display: none;
		}
		.widgets_area {
			padding: 0;
			margin: -65px 0 0 0;
			width: 100%;
		}
		.widget {
			display: inline-block;
			vertical-align: top;
			width: 29.33333333333333%;
			padding: 1% 2%;
			margin: 0 2%;
		}
	}
	.site_wrapper .archive_header {
		left: 0;
		right: 0;
		top: 210px;
		margin: 0 auto;
		h1 {
			width: 75%;
			margin: 0 auto;
		}
	}
	.site_wrapper > .main_column {
		display: block;
		position: inherit;
		top: 0;
		width: 96%;
		margin: 0 2% 30px;
		min-height: inherit;

		.widgets_area {
			.widget.upcoming_events,
			.widget.recent_posts {
				width: 48%;
				padding: 1% 2% 4%;
				margin-bottom: 0;
			}
			.widget.upcoming_events {
				margin-right: 2%;
			}
			.widget.recent_posts {
				margin-left: 2%;
			}
		}
	}
	.site_wrapper > .right_column {
		display: block;
		width: 100%;

		.widgets_area {
			padding: 0;
			margin: 0 0 30px 0;
			width: 100%;
		}
		.widget {
			display: inline-block;
			vertical-align: top;
			top: 0;
			width: 29.33333333333333%;
			padding: 1% 2%;
			margin: 0 2%;
		}
	}

	footer {
		.left_column,
		.main_column {
			display: block;
		}
		.left_column {
			width: 45%;
			margin: 0 auto 30px;
		}
		.main_column {
			width: 96%;
			margin: 0 2%;

			.widget:nth-child(1),
			.widget:nth-child(5) {
				width: 20%;
				margin: 0;
			}
			.widget:nth-child(3) {
				width: 40%;
			}
			.widget:nth-child(4) {
				width: 20%;
			}
			.widget:nth-child(7) {
				width: 21%;
			}
		}
	}
}
@media (min-width: 641px) {
	.site_wrapper > .main_column {
		main[data-columns]::before {
			content: '3 .column.size-1of3';
		}
	}
}
@media (max-width: 840px) {
	.main_column .naaft_cal {
		& > header nav a,
		& > nav a {
			@include Tablet(22px,normal,700,25px);

			span {
				top: -4px;
			}
		}
		& > nav .back,
		& > nav .forward {
			bottom: 10px;
		}
		& > section {
			header {
				display: none;
			}

			.row {
				border-bottom: none;
			}

			.row div.date {
				display: block;
				width: 100%;
				border-left: 1px solid #ccc6ba;
				border-bottom: 1px solid #ccc6ba;
				border-right: 1px solid #ccc6ba;
			}

			.vr {
				display: none;
			}
		}
	}
}
@media (max-width: 768px) {
	.image_masthead .masthead_blurred {
		top: -10px;
	}
}
@media (max-width: 640px) {
	#sunrise_main_logo_responsive {
		height: 100px;
	}
	.header_tab {
		width: 100%;
		top: 100px;
		a#h_p_tab {
			display: block;
			margin: 0 auto;
		}
	}
	.site_wrapper .archive_header {
		top: 162px;
	}

	.sunrise_search {
		display: block;
		position: absolute;
		top: 0;
		right: 30px;
		margin: 0;
		background: none;
		padding: 0;
		border-radius: 0;
		box-shadow: none;
		z-index: 10000;

		fieldset {
			padding: 0;
			background: url(../images/tex/burlap.jpg);
			-webkit-border-bottom-left-radius: 5px;
			-webkit-border-bottom-right-radius: 5px;
			-moz-border-radius-bottomleft: 5px;
			-moz-border-radius-bottomright: 5px;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			
			&.pop .screen {
				display: block;
				position: fixed;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(255,255,255,0.9);
				cursor: pointer;
			}
			&.pop input {
				position: fixed;
				display: block;
				width: 80%;
				left: 10%;
				right: 0;
				top: 100px;
				background-color: transparent;
				border-radius: 0;
				border-bottom: 1px solid #2d2d2d !important;
				padding-bottom: 7px !important;
				font-size: 24px;
				color: #2d2d2d;
			}
		}
		input {
			position: fixed;
			top: 0;
			right: -100%;

			&::-webkit-input-placeholder {
			   @include Aleo(20px,italic,200,28px);
			   color: #666666;
			}
			&:-moz-placeholder { /* Firefox 18- */
			   @include Aleo(20px,italic,200,28px);
			   color: #666666;  
			}
			&::-moz-placeholder {  /* Firefox 19+ */
			   @include Aleo(20px,italic,200,28px);
			   color: #666666;  
			}
			&:-ms-input-placeholder {  
			   @include Aleo(20px,italic,200,28px);
			   color: #666666;  
			}
		}
		label {
			display: block;
			margin: 0;
			padding: 0;
			position: inherit;
			width: 44px;
			height: 44px;
			background: url(../images/icons/mag_glass.svg);
			background-repeat: no-repeat;
			background-size: 60% auto;
			background-position: center;
		}
	}
	.site_wrapper > .left_column {
		.widget {
			display: block;
			width: 96%;
			margin: 0 2% 30px;
			cursor: pointer;

			.post_content,
			.sales_flyer_content {
				max-height: 0;
				overflow: hidden;
				-webkit-transition: max-height 0.5s cubic-bezier(0, 1.05, 0, 1);
				-moz-transition: 	max-height 0.5s cubic-bezier(0, 1.05, 0, 1);
				-o-transition: 		max-height 0.5s cubic-bezier(0, 1.05, 0, 1);
				transition: 		max-height 0.5s cubic-bezier(0, 1.05, 0, 1);
				text-align: center;
			}

			&.open .post_content,
			&.open .sales_flyer_content {
				max-height: 1000px;
				-webkit-transition: max-height 0.5s ease-in-out;
				-moz-transition: 	max-height 0.5s ease-in-out;
				-o-transition: 		max-height 0.5s ease-in-out;
				transition: 		max-height 0.5s ease-in-out;
			}

			&:after {
				content: 'Show Content';
				display: block;
				width: 100%;
				padding: 8px;
				text-align: center;
				@include Aleo(15px,normal,600,20px);
				cursor: pointer;
				background-color: $green;
				color: #ffffff;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 3px;
				margin-top: 10px;
				margin-bottom: 5px;
			}
			&.open:after {
				content: 'Hide Content';
				background-color: darken($green, 10%);
			}
		}
	}
	.site_wrapper > .main_column {
		.widget,
		.widgets_area .widget.upcoming_events,
		.widgets_area .widget.recent_posts {
			display: block;
			width: 100%;
			margin: 0 0 30px;
		}
		.widgets_area .yarpp-related-widget .widget {
			display: block;
			width: 100%;
			margin: 0 0 30px;
		}

		main[data-columns]::before {
			content: '1 .column.size-1of1';
		}

		.size-1of1 {
			width: 100%;
		}
	}
	.site_wrapper > .right_column {
		.widget {
			display: block;
			width: 96%;
			margin: 0 2% 30px;
		}
	}
	footer {
		.main_column {
			width: 96%;
			margin: 0 2%;

			.widget:nth-child(5n-4),
			.widget:nth-child(5n-3),
			.widget:nth-child(5n-2),
			.widget:nth-child(5n-1),
			.widget:nth-child(5n) {
				width: 25%;
			}
			.widget div {
				display: none;
			}
			.widget:nth-child(6) {
				& {
					text-align: center;
				}
				div {
					display: block;

					address {
						margin-right: 0;
					}
				}
			}
		}
	}
}
@media (max-width: 568px) {
	#menu_wrapper>#main_nav {
		width: 250px;
	}
	.hamburger.close {
		-webkit-transform: translate3d(250px, 0px, 0px);
		transform: translate3d(250px, 0px, 0px);
	}
	.site_wrapper .archive_header {
		width: 95%;
		h1 {
			width: 90%;
		}
	}
	.image_masthead {
		height: 218px !important;
	}
	.image_masthead .masthead_blurred {
		top: -10px !important;
	}
	.forcefullwidth_wrapper_tp_banner {
		display: none;
	}
	.site_wrapper > .left_column,
	.site_wrapper > .right_column {
		.widget {
			&.recipe_of_month,
			&.tip_of_week,
			&.deal_of_week,
			&.sales_flyer,
			&.staff_picks {
				a.see_more {
					font-size: 18px;
					padding: 4px 9px 6px;
				}
			}
		}
	}
	.site_wrapper > .main_column {
		#featured_article a.image {
			max-width: 100%;
			float: none;
			margin-right: 0;
		}

		article {
			.alignleft,
			.alignright {
				display: block;
				width: 100%;
				float: none;
				margin: 0 0 20px 0;
			}
			a.see_more {
				font-size: 18px;
				padding: 4px 9px 6px;
			}
			.article_meta,
			.event_meta {

				.article_tags,
				.event_tags,
				.article_categories,
				.event_categories {
					display: block;
					width: 100%;
					max-width: 100%;
					margin: 0 0 10px 0;
				}

				.article_comments,
				.event_comments {
					position: initial;
					display: block;
					background-image: url(../images/icons/comment_icon.svg);
					background-repeat: no-repeat;
					height: 20px;
					padding-left: 28px;
					color: $orange;
				}
			}
			ul.section_menu {
				a.featured_image {
					display: block;
					width: 100%;
					max-width: 100%;
					margin: 0 0 20px;
				}
				div {
					width: 100%;
				}
			}
		}

		.widgets_area .widget.upcoming_events,
		.widgets_area .widget.recent_posts {
			.post_content {
				margin-bottom: 25px;
			}
		}
		.widgets_area .widget.recent_posts {
			div.featured_image {
				width: 100%;
				margin: 0 0 20px;
			}
			.post_meta {
				width: 100%;
			}
		}
		.widgets_area .yarpp-related-widget .widget a.see_more {
			font-size: 18px;
			padding: 4px 9px 6px;
		}
		.naaft_cal>header h3 {
			margin-bottom: 20px
		}
		.naaft_cal>header nav .back,
		.naaft_cal>nav .back,
		.naaft_cal>header nav .forward,
		.naaft_cal>nav .forward, {
			position: initial;
			display: block;
			width: 80%;
			margin: 0 auto 10px;

			a {
				display: block;
				text-align: center;
			}

		}

	}
	#comments {
		#respond form input {
			width: 100%;
		}
		#respond form textarea {
			margin-bottom: 20px;
		}
		#respond .form-submit {
			display: block;
			width: 100%;
			position: initial;
			margin: 0 0 15px;
		}
		.commentlist section .comment-reply a {
			font-size: 18px;
			padding: 4px 9px 6px;
		}
	}

	footer {
		.left_column {
			width: 60%;
		}
		.main_column {
			.widget:nth-child(5n-4),
			.widget:nth-child(5n-3),
			.widget:nth-child(5n-2),
			.widget:nth-child(5n-1),
			.widget:nth-child(5n) {
				width: 100%;
				display: block;
				text-align: center;
			}
		}
	}
}