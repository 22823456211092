@font-face {
    font-family: 'Aleo';
    src: url('fonts/Aleo-Light-webfont.eot');
    src: url('fonts/Aleo-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Aleo-Light-webfont.woff') format('woff'),
         url('fonts/Aleo-Light-webfont.ttf') format('truetype'),
         url('fonts/Aleo-Light-webfont.svg#aleolight') format('svg');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Aleo';
    src: url('fonts/Aleo-LightItalic-webfont.eot');
    src: url('fonts/Aleo-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Aleo-LightItalic-webfont.woff') format('woff'),
         url('fonts/Aleo-LightItalic-webfont.ttf') format('truetype'),
         url('fonts/Aleo-LightItalic-webfont.svg#aleolightitalic') format('svg');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'Aleo';
    src: url('fonts/Aleo-Regular-webfont.eot');
    src: url('fonts/Aleo-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Aleo-Regular-webfont.woff') format('woff'),
         url('fonts/Aleo-Regular-webfont.ttf') format('truetype'),
         url('fonts/Aleo-Regular-webfont.svg#aleoregular') format('svg');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Aleo';
    src: url('fonts/Aleo-Italic-webfont.eot');
    src: url('fonts/Aleo-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Aleo-Italic-webfont.woff') format('woff'),
         url('fonts/Aleo-Italic-webfont.ttf') format('truetype'),
         url('fonts/Aleo-Italic-webfont.svg#aleoitalic') format('svg');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Aleo';
    src: url('fonts/Aleo-Bold-webfont.eot');
    src: url('fonts/Aleo-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Aleo-Bold-webfont.woff') format('woff'),
         url('fonts/Aleo-Bold-webfont.ttf') format('truetype'),
         url('fonts/Aleo-Bold-webfont.svg#aleobold') format('svg');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Aleo';
    src: url('fonts/Aleo-BoldItalic-webfont.eot');
    src: url('fonts/Aleo-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Aleo-BoldItalic-webfont.woff') format('woff'),
         url('fonts/Aleo-BoldItalic-webfont.ttf') format('truetype'),
         url('fonts/Aleo-BoldItalic-webfont.svg#aleobolditalic') format('svg');
    font-weight: 700;
    font-style: italic;
}
/* Mathlete Skinny */
@font-face {
    font-family: 'Mathlete';
    src: url('fonts/Mathlete-Skinny-webfont.eot');
    src: url('fonts/Mathlete-Skinny-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Mathlete-Skinny-webfont.woff') format('woff'),
         url('fonts/Mathlete-Skinny-webfont.ttf') format('truetype'),
         url('fonts/Mathlete-Skinny-webfont.svg#mathleteskinny') format('svg');
    font-weight: 400;
    font-style: normal;
}
/* Mathlete Skinny Italic */
@font-face {
    font-family: 'Mathlete';
    src: url('fonts/Mathlete-SkinnySlant-webfont.eot');
    src: url('fonts/Mathlete-SkinnySlant-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Mathlete-SkinnySlant-webfont.woff') format('woff'),
         url('fonts/Mathlete-SkinnySlant-webfont.ttf') format('truetype'),
         url('fonts/Mathlete-SkinnySlant-webfont.svg#mathleteskinny_slant') format('svg');
    font-weight: 400;
    font-style: italic;
}
/* Mathlete Bulky */
@font-face {
    font-family: 'Mathlete';
    src: url('fonts/Mathlete-Bulky-webfont.eot');
    src: url('fonts/Mathlete-Bulky-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Mathlete-Bulky-webfont.woff') format('woff'),
         url('fonts/Mathlete-Bulky-webfont.ttf') format('truetype'),
         url('fonts/Mathlete-Bulky-webfont.svg#mathletebulky') format('svg');
    font-weight: 600;
    font-style: normal;
}
/* Mathlete Bulky Italic */
@font-face {
    font-family: 'Mathlete';
    src: url('fonts/Mathlete-BulkySlant-webfont.eot');
    src: url('fonts/Mathlete-BulkySlant-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/Mathlete-BulkySlant-webfont.woff') format('woff'),
         url('fonts/Mathlete-BulkySlant-webfont.ttf') format('truetype'),
         url('fonts/Mathlete-BulkySlant-webfont.svg#mathletebulky_slant') format('svg');
    font-weight: 600;
    font-style: italic;
}